import React, { useEffect, useState } from "react";
import HelmetExport, { Helmet } from "react-helmet";
import PopupContainer from "../../components/general/popup";
import BaseHeader from "../../components/general/baseHeader";
import Footer from "../../components/general/footer";
import LoadingIcon from "../../components/general/loadingIcon";
import api, { API_URL } from "../../api";
import toast from "react-hot-toast";

import '../../assets/css/pages/pricing/pricing.css'

const CheckoutForm = ({ plan }) => {
    const planId = plan.id
    const [phoneNumber, setPhoneNumber] = useState()


    const handlePayment = async () => {
        const checkoutData = {
            "payment_mode": "MoMo",
            "plan_id": planId,
            "phone_number": phoneNumber
        }
        try {
            const response = await api.post(`${API_URL}/payments/checkout/`, checkoutData)
            if (response.status === 201) {
                toast.success("Transaction is created")
            }
        } catch (error) {
            if (error.response.data) {
                toast.error(error.response.data.error)
            } else {
                toast.error("There was an error")
            }
        }
    }
    return (
        <div className="">
            <h1>Checkout</h1>
            <p>After payment, you'll pe provided with a space to upload {plan.product_count} and {plan.images_count} images</p>
            {/* <div className="card-payment">
                Coming soon
                <p>We're working with our partners to brings this feature to life</p>
            </div> */}
            <form className="form" id="checkoutForm">
                <h4>Before you click pay, make sure you have the balance on your account</h4>
                <div className="mobile-payment">
                    <input
                        onChange={(e) => setPhoneNumber(e.target.value)}
                        type="text" name="phoneNumber" id="phoneNUmber" placeholder="Phone number" />
                    <button
                        onClick={handlePayment}
                        type="button" className="primary-button">
                        Click to pay
                    </button>
                </div>
                <small>By cliking, you allow us to charge the {plan.price} from your Mobile money account</small>
            </form>
        </div>
    )
}

const PricingPage = () => {
    const [plans, setPlans] = useState([])
    const [isLoading, setIsLoading] = useState(false)
    const [selectedPlan, setSelectedPlan] = useState(null)
    const [isFormOpen, setIsFormOpen] = useState(false)

    const toggleCheckout = (plan) => {
        setSelectedPlan(plan)
        setIsFormOpen(!isFormOpen)

    }

    useEffect(() => {
        setIsLoading(true)
        const gettingPlan = async () => {
            try {
                const response = await api.get(`${API_URL}/vendors/plans/`)
                if (response.status === 200) {
                    setPlans(response.data)
                    setIsLoading(false)
                }
            } catch (error) {
                if (error.response.data) {
                    toast.error(error.response.data.error)
                } else {
                    toast.error("Error getting plans...")
                }
            }
        }

        gettingPlan()
    }, [])
    return (
        <div>
            <HelmetExport>
                <title>Pricing | Huzahills</title>
            </HelmetExport>
            <BaseHeader />
            <div className="pricing-page">
                <div className="container">
                    <div className="pricing-header">
                        <h1 className="title"> Choose your package</h1>
                        <p>Lorem ipsum dolor sit amet, consectetur adipisicing elit. Asperiores quo tenetur quidem tempora, corrupti vitae voluptates aliquid sapiente minima, recusandae alias! Amet dicta explicabo itaque natus distinctio delectus, aperiam expedita.</p>

                    </div>
                    {
                        isLoading
                            ? <LoadingIcon text={"Loading pricing plans"} />
                            : <div className="pricing-plans">
                                {
                                    plans.map((plan, index) => (
                                        <div key={index} className={`plan ${plan.price === "150.00" ? 'promo' : ''}`}>
                                            <div className="plan-header">
                                                <div className="icon">
                                                    <i className="fa-solid fa-leaf"></i>
                                                </div>
                                                <h3 className="plan-title">{plan.name}</h3>
                                            </div>

                                            <div className="pricing">
                                                <h2 className="amount">{plan.price}<span>Rwf</span></h2>
                                                <span>/</span>
                                                <p className="duration">Month</p>
                                            </div>

                                            <div className="details">
                                                <div className="detail">
                                                    <div className="icon"></div>
                                                    <p>Up to {plan.product_count} products</p>
                                                </div>
                                                <div className="detail">
                                                    <div className="icon"></div>
                                                    <p>Up to {plan.images_count} product images</p>
                                                </div>
                                                <div className="detail">
                                                    <div className="icon"></div>
                                                    <p>Emails support</p>
                                                </div>
                                            </div>

                                            <button
                                                onClick={() => toggleCheckout(plan)}
                                                className="action-button">
                                                Get started
                                            </button>
                                        </div>
                                    ))
                                }
                            </div>
                    }

                </div>
            </div>
            {
                isFormOpen
                    ? <PopupContainer content={<CheckoutForm plan={selectedPlan} />} closePopup={toggleCheckout} />
                    : ''
            }
            <Footer />
        </div>
    )
}

export default PricingPage