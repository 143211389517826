import React from "react";
import { Navigate } from "react-router-dom";
import { useAuthentication } from "./authContext";

function ProtectedRoute({ children }) {
    const { isAuth, } = useAuthentication()
    if (isAuth == null) {
        return null
    }

    return isAuth ? children : <Navigate to="/accounts/" />
}

export default ProtectedRoute
