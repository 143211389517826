import axios from "axios"
export const API_URL = process.env.REACT_APP_API_URL;
export const AuthClientId = process.env.REACT_APP_KINDE_CLIENT_ID
export const AuthDomain = process.env.REACT_APP_KINDE_DOMAIN
export const AuthRedirectUrl = process.env.REACT_APP_KINDE_REDIRECT_URL
export const AuthLogout = process.env.REACT_APP_KINDE_LOGOUT_URL
export const GoogleClientId = process.env.REACT_APP_GOOGLE_CLIENT_ID


const api = axios.create({
    baseURL: API_URL
})

api.interceptors.request.use(
    (config) => {
        const accessToken = localStorage.getItem("access");
        if (accessToken) {
            config.headers.Authorization = `Bearer ${accessToken}`
        } return config
    }, (error) => {
        return Promise.reject(error)
    }
)

export default api