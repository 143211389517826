import React, { useEffect, useState } from "react";
import axios from 'axios';
import toast, { LoaderIcon } from "react-hot-toast";
import { useGoogleLogin } from '@react-oauth/google';
import { Helmet } from "react-helmet";
import { Link } from "react-router-dom";
import { API_URL } from "../../api";
import { promoData } from "../../constants";
import { useTranslation } from "react-i18next";
import LoadingIcon from "../general/loadingIcon";

const LoginForm = ({ switchForm }) => {
    const { t } = useTranslation()
    const [accessToken, setAccessToken] = useState("")
    const [promoPercentage, setPromoPercentage] = useState(50)
    const [promoDescription, setPromoDescription] = useState("")
    const [loggingIn, setLoggingIn] = useState(false)
    const [loggingInWithGoogle, setLoggingInWithGoogle] = useState(false)
    const [showPassword, setShowPassword] = useState(false)

    // form
    const [formError, setFormError] = useState(null)
    const [userEmail, setUserEmail] = useState("")
    const [userPassword, setUserPassword] = useState("")

    const toggleShowPassword = () => {
        setShowPassword(!showPassword)
    }
    const login = useGoogleLogin({
        onSuccess: tokenResponse => {
            setAccessToken(tokenResponse.access_token);
        },
    });

    const handleEmailLogin = async (e) => {
        setLoggingIn(true)
        if (userEmail === "" & userPassword === "") {
            toast.error("Emails and password are required to login")
            setLoggingIn(false)
        } else if (userEmail === "") {
            toast.error("Email is required")
            setLoggingIn(false)
        } else if (userPassword === "") {
            toast.error("Password is required")
            setLoggingIn(false)
        } else {
            const userCredentials = {
                "user_email": userEmail,
                "user_password": userPassword
            }
            try {
                const response = await axios.post(`${API_URL}/accounts/token/`, userCredentials)
                if (response.status === 200) {
                    localStorage.setItem("access", response.data.access)
                    window.location.href = "/"
                    setLoggingIn(false)
                }
            } catch (error) {
                if (error.response.data) {
                    toast.error(error.response.data.error)
                    setLoggingIn(false)
                }
                else {
                    toast.error("Network Error")
                    setLoggingIn(false)
                }
            }
        }
    }
    useEffect(() => {
        if (accessToken) {
            axios
                .get(`https://www.googleapis.com/oauth2/v1/userinfo?access_token=${accessToken}`, {
                    headers: {
                        Authorization: `Bearer ${accessToken}`,
                        Accept: 'application/json'
                    }
                })
                .then((res) => {
                    setLoggingInWithGoogle(true);
                    axios.post(`${API_URL}/accounts/token/social/google/`, { "access_token": accessToken })
                        .then((response) => {
                            console.log(response);
                            if (response.status === 200) {
                                setLoggingInWithGoogle(false);
                                localStorage.setItem("access", response.data.access);
                                window.location.href = "/"
                            }
                        })
                        .catch((error) => {
                            if (error.response.data) {
                                setFormError(error.response.data.error);
                                console.log(error.response.data.error);
                                toast.error(error.response.data.error)
                                setLoggingInWithGoogle(false);
                            } else {
                                toast.error("Network Error")
                                setLoggingInWithGoogle(false);
                            }
                        });

                })
                .catch((err) => console.log(err));
        };

        //promoData
        const getPromoData = () => {
            setPromoDescription(promoData.description)
            setPromoPercentage(promoData.percentage)
        }

        getPromoData()
    },
        [accessToken]
    );

    return (
        <div>
            <Helmet>
                <title>Login | Huzahills</title>
            </Helmet>
            <div className="account-form">
                <h2 className="title">{t("login.title")}</h2>
                <p>{t("login.description")}</p>

                <form className="form" id="loginForm">
                    <input
                        onChange={(e) => setUserEmail(e.target.value)}
                        value={userEmail}
                        type="email" name="userEmail" id="userEmail" placeholder={t("login.form.email")} />
                    <div className="password-input">
                        <input
                            onChange={(e) => setUserPassword(e.target.value)}
                            value={userPassword}
                            type={showPassword ? "text" : "password"} name="userPassword" id="userPassword" placeholder={t("login.form.password")} />
                        <div onClick={toggleShowPassword} className="icon">
                            {
                                showPassword ?
                                    <i className="fa-regular fa-eye"></i>
                                    : <i className="fa-regular fa-eye-slash"></i>
                            }
                        </div>
                    </div>

                    <div className="buttons">
                        <button type="button" onClick={handleEmailLogin} className="primary-button login-button">
                            {/* <LoadingIcon text={"Logging in"} /> */}
                            {loggingIn
                                ? <LoadingIcon />
                                : <span>{t("buttons.login")}</span>}
                        </button>

                        <p>{t("login.forgot_password")}? <Link to={"/accounts/forgot-password/"}>{t("click_here")}</Link></p>
                    </div>
                </form>

                <span>{t("login.or")}</span>
                <button className="outline-button" onClick={() => login()}>
                    <div className="icon">
                        <i className="fa-brands fa-google"></i>
                    </div>
                    {
                        loggingInWithGoogle
                            ? <LoadingIcon />
                            : <span>{t("login.google")}</span>
                    }
                </button>

                <p>{t("login.have_account")}? <span className="form-link" onClick={switchForm}>{t("buttons.sign_up")}</span></p>
            </div>
        </div>
    )
}

export default LoginForm