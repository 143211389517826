import React, { useEffect, useState } from "react";
import { Helmet } from "react-helmet";
import BaseHeader from "../../components/general/baseHeader";
import Footer from "../../components/general/footer";
import { promoData } from "../../constants";
import 'react-day-picker/dist/style.css';
import toast from "react-hot-toast";
import api, { API_URL } from "../../api";


const NewAccountForm = () => {
    const [accountType, setAccountType] = useState(null)
    // form
    const [phoneNumber, setPhoneNumber] = useState("")
    const [country, setCountry] = useState("")
    const [state, setState] = useState("")
    const [address, setAddress] = useState("")
    const [cityOrDistrict, setCityOrDistrict] = useState("")

    // personal info
    const [gender, setGender] = useState("")
    const [dateOfBirth, setBirthOfDate] = useState("")

    // business info

    const [businessName, setBusinessName] = useState("")
    const [businessDescription, setBusinessDescription] = useState("")
    const [platformDuration, setPlatformDuration] = useState("")
    const [numberOfProducts, setNumberOfProducts] = useState("")

    const handleSubmit = async () => {
        console.log("I am clicked")
        if (accountType === "personal") {
            if (phoneNumber === "" || country === "" || state === "" || address === "" || cityOrDistrict === "" || gender === "" || dateOfBirth === "") {
                toast.error("All fields are required")
            }
        } else if (accountType === "business") {
            if (businessName === "" || businessDescription === "" || platformDuration === "" || numberOfProducts) {
                toast.error("All fields are required")
            }
        } else if (accountType === null) {
            toast.error("Please chose the type of account to create")
        }
        const userInfo = {
            "account_type": accountType,
            "phone_number": phoneNumber,
            "country": country,
            "state": state,
            "address": address,
            "city": cityOrDistrict,
            "gender": gender,
            "date_of_birth": dateOfBirth,
            "business_name": businessName,
            "business_description": businessDescription,
            "platform_duration": platformDuration,
            "products_number": numberOfProducts
        }

        console.log(userInfo)

        try {

            const response = await api.post(`${API_URL}/accounts/new-account/`, userInfo)
            if (response.status === 201) {
                toast.success("Account created")
                if (accountType === "vendor") {
                    window.location.href = "/pricing/"
                } else {
                    window.location.href = "/"
                }
            }
        } catch (error) {
            console.log(error)
            if (error.response.data) {
                toast.error(error.response.data.error)
            } else {
                toast.error("There was an error")
            }
        }

    }
    return (
        <div>
            <div className="account-form">
                <h1 className="title">Account Details</h1>

                <form className="form" id="newAccountForm">
                    {
                        accountType === "personal" || accountType === null
                            ? <div className="half">
                                <input
                                    onChange={(e) => setPhoneNumber(e.target.value)}
                                    value={phoneNumber}
                                    type="text" name="phoneNumber" id="phoneNumber" placeholder="Phone number" />
                                <input
                                    onChange={(e) => setCountry(e.target.value)}
                                    type="text" name="userCountry" id="userCountry" placeholder="Your country" />
                                <input
                                    onChange={(e) => setState(e.target.value)}
                                    value={state}
                                    type="text" name="provinceOrState" id="provinceOrState" placeholder="Province or State" />
                                <input
                                    onChange={(e) => setCityOrDistrict(e.target.value)}
                                    type="text" name="cityOrDistrict" id="cityOrDistrict" placeholder="City" />
                                <input
                                    onChange={(e) => setAddress(e.target.value)}
                                    type="text" name="userAddress" id="userAddress" placeholder="Address" />
                            </div>
                            : ''
                    }
                    <div className="account-type">
                        Select account type
                        <div className="half">
                            <div
                                onClick={() => setAccountType("personal")}
                                className={accountType === "personal" ? "type selected" : "type"}><span>Personal</span></div>
                            <div
                                onClick={() => setAccountType("vendor")}
                                className={accountType === "vendor" ? "type selected" : "type"}><span>Vendor</span></div>
                        </div>
                    </div>
                    {
                        accountType === "personal" || accountType === null
                            ? <div className="personal-info">
                                <div className="half">
                                    <select
                                        onChange={(e) => setGender(e.target.value)}
                                        name="userGender" id="userGender">
                                        <option value="isMale">Male</option>
                                        <option value="isFemale">Female</option>
                                        <option value="isOther">Other</option>
                                    </select>
                                    <input onChange={(e) => setBirthOfDate(e.target.value)} type="date" name="dateOfBirth" id="dateOfBirth" placeholder="Date of birth" />
                                </div>
                            </div>
                            : <div className="vendor-info">
                                <input
                                    onChange={(e) => setBusinessName(e.target.value)}
                                    type="text" name="businessName" id="businessName" placeholder="Business name" />
                                <textarea
                                    onChange={(e) => setBusinessDescription(e.target.value)}
                                    name="businessDescription" id="businessDescription" placeholder="Business description" rows={4}></textarea>
                                <div className="filed-with-label">
                                    <p>How long will you stay on the platform?</p>
                                    <input
                                        onChange={(e) => setPlatformDuration(e.target.value)}
                                        type="number" name="platformDuration" id="platformDuration" placeholder="Enter months" />
                                </div>

                                <div className="filed-with-label">
                                    <p>How many products are you planning to sell?</p>
                                    <input
                                        onChange={(e) => setNumberOfProducts(e.target.value)}
                                        type="number" name="platformDuration" id="platformDuration" placeholder="Enter a number" />
                                </div>
                            </div>

                    }


                    <button type="button"
                        onClick={handleSubmit}
                        className="button primary-button">
                        <span>Create an account</span>
                    </button>
                </form>
            </div>
        </div>
    )
}

const NewAccountPage = () => {
    const [promoPercentage, setPromoPercentage] = useState(50)
    const [promoDescription, setPromoDescription] = useState("")

    useEffect(() => {
        const getPromoData = () => {
            setPromoDescription(promoData.description)
            setPromoPercentage(promoData.percentage)
        }
        getPromoData()
    })
    return (
        <div>
            <Helmet><title>New Account | Huzahills</title></Helmet>
            <BaseHeader />
            <div className="accounts-page">
                <div className="container">
                    <NewAccountForm />
                    <div className="promo">
                        <div className="promo-img">
                            <img src={"/images/accounts/login promo.png"} alt="" />
                            <div className="promo-content">
                                <h1>Get {promoPercentage}%</h1>
                                <p>{promoDescription}</p>
                            </div>

                            <div className="overlay"></div>
                        </div>
                    </div>
                </div>
            </div>
            <Footer />
        </div>
    )
}

export default NewAccountPage