import React from "react";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";


const Footer = () => {
    const { t } = useTranslation()
    return (
        <footer className="footer">
            <div className="container">
                <div className="row">
                    <div className="col">
                        <h2>{t("footer.about.title")}</h2>
                        <ul>
                            <li>
                                <Link to="/">{t("footer.about.links.about")} </Link>
                            </li>
                            <li>
                                <Link to="/">{t("footer.about.links.faqs")} </Link>
                            </li>
                        </ul>
                    </div>
                    <div className="col">
                        <h2>{t("footer.support.title")}</h2>
                        <ul>
                            <li>
                                <Link to="/">{t("footer.support.links.contact")}</Link>
                            </li>
                            <li>
                                <Link to="/">{t("footer.support.links.help")}</Link>
                            </li>
                            <li>
                                <Link to="/vendor-register">{t("footer.support.links.seller")}</Link>
                            </li>
                            <li>
                                <Link to="/">{t("footer.support.links.policies")}</Link>
                            </li>
                            <li>
                                <Link to="/">{t("footer.support.links.report")}</Link>
                            </li>
                        </ul>
                    </div>
                    <div className="col">
                        <h2>{t("footer.categories.title")}</h2>
                        <ul>
                            <li>
                                <Link to="/">{t("footer.categories.links.1")}</Link>
                            </li>
                            <li>
                                <Link to="/">{t("footer.categories.links.2")}</Link>
                            </li>
                            <li>
                                <Link to="/">{t("footer.categories.links.3")}</Link>
                            </li>
                            <li>
                                <Link to="/">{t("footer.categories.links.4")}</Link>
                            </li>
                            <li>
                                <Link to="/">{t("footer.categories.links.5")}</Link>
                            </li>
                        </ul>
                    </div>

                    {/* <div className="col">
                        <h2>HUZA Hills Legal</h2>
                        <ul>
                            <li>
                                <Link to="/">About HUZA hills </Link>
                            </li>
                            <li>
                                <Link to="/">Privacy Policy </Link>
                            </li>
                            <li>
                                <Link to="/">Terms and conditions </Link>
                            </li>
                        </ul>
                    </div> */}
                </div>

                <div className="copyrights">
                    <div className="socialMedia">
                        <Link to="facebook.com">
                            <i className="social-icon fab fa-facebook-f"></i>
                        </Link>
                        <Link to="https://instagram.com/huzahills?igshid=YTQwZjQ0NmI0OA==">
                            <i className="social-icon fab fa-instagram"></i>
                        </Link>
                        <Link to="https://x.com/huzahills?s=21">
                            <i className="fa-brands fa-x-twitter"></i>
                        </Link>
                        <Link to="https://www.tiktok.com/@huza.hills?_t=8gzQTaX3a7C&_r=1">
                            <i className="fa-brands fa-tiktok"></i>
                        </Link>
                        <Link to="https://www.linkedin.com/in/huza-hills-8a1656299/">
                            <i className="fa-brands fa-linkedin-in"></i>
                        </Link>
                        <Link to="/login">{t("footer.bottom.links.users")}</Link>
                        <Link to="/vendor-register">{t("footer.bottom.links.vendors")}</Link>
                    </div>

                    <div className="copyright">
                        <p>
                            {t("footer.bottom.rights.text")}
                            <Link
                                className="link-inline-link external-link"
                                to="https://csrlimited.com/"
                                target="_blank"
                            >
                                <span> CSR Limited</span>
                            </Link>
                        </p>
                    </div>
                </div>
            </div>
        </footer>
    )
}

export default Footer