import React from "react";
import { Link } from "react-router-dom";
import PopupContainer from "../components/general/popup";
import NotifyMeForm from "../components/forms/notifyMeForm";
import { useState } from "react";
import "../css/marketing.css";
import { useTranslation } from 'react-i18next';
import Footer from "../components/general/footer";
// import "../js/scripts.js";


const Marketing = () => {
  const { t } = useTranslation();
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [popupOpen, setPopupOpen] = useState(false)

  const togglePopup = () => {
    setPopupOpen(!popupOpen)
  }

  const handleOpenMenu = () => {
    setIsMenuOpen(true);
  };

  const handleCloseMenu = () => {
    setIsMenuOpen(false);
  };

  const NotifyButton = () => {
    return (
      <button onClick={togglePopup} className="btn btn-primary open-pop-up">
        {t('buttons.notify_me')}
        <span>
          <i className="fa fa-bell-o icon-link"></i>
        </span>
      </button>
    )
  }
  return (
    <>
      <header>
        <nav className="container">
          <div className="branding">
            <img
              src={"/images/branding/huzahills-icon-green.png"}
              alt=""
              className="site-icon"
            />
            <h1 className="site-name">Huzahills</h1>
          </div>
          <div className={isMenuOpen ? "menu-open" : "menu"}>
            {/* <ul className="menu-list">
                            <li className="menu-item"><a href="">Platform</a></li>
                            <li className="menu-item"><a href="">Apps</a></li>
                            <li className="menu-item"><a href="">Company</a></li>
                            <li className="menu-item"><a href="">Partnership</a></li>
                        </ul> */}
            <div className="social-icons">
              {/* <a href=""><i className="social-icon fab fa-facebook-f"></i></a> */}
              <Link to="https://instagram.com/huzahills?igshid=YTQwZjQ0NmI0OA==">
                <i className="social-icon fab fa-instagram"></i>
              </Link>
              <Link to="https://x.com/huzahills?s=21">
                <i className="fa-brands fa-x-twitter"></i>
              </Link>
              <Link to="https://www.tiktok.com/@huza.hills?_t=8gzQTaX3a7C&_r=1">
                <i className="fa-brands fa-tiktok"></i>
              </Link>
              <Link to="https://www.linkedin.com/in/huza-hills-8a1656299/">
                <i className="fa-brands fa-linkedin-in"></i>
              </Link>
            </div>

            <NotifyButton />

          </div>
          {!isMenuOpen && (
            <img
              src={"/images/client/icons/menu-icon-open.svg"}
              alt=""
              className={"open-menu"}
              onClick={handleOpenMenu}
            />
          )}

          {isMenuOpen && (
            <img
              src={"/images/client/icons/menu-icon-close.svg"}
              alt=""
              className={"close-menu"}
              onClick={handleCloseMenu}
            />
          )}
        </nav>
      </header>

      <main>
        {
          popupOpen
            ?
            <PopupContainer content={<NotifyMeForm togglePopup={togglePopup} />} closePopup={togglePopup} />
            : ''
        }

        <section className="hero">
          <div className="container">
            <h1 className="site-title">Huzahills</h1>
            <p className="intro-text">
              {t("marketing.hero.welcome_text")}
            </p>
            <NotifyButton />
            <div className="promo-images">
              <img
                src={"/images/client/index/intro.png"}
                alt=""
                className="promo-big-image shadow"
              />
              <img
                src={"/images/client/index/intro-mobile.png"}
                alt=""
                className="promo-small-image shadow"
              />
            </div>
          </div>
        </section>

        <section className="chat">
          <div className="container">
            <h1 className="title">{t("marketing.interaction.title")}</h1>
            <p>{t("marketing.interaction.description")}</p>
            <NotifyButton />
            <div className="chat-images">
              <img
                src={"/images/client/index/chat-screen-mobile.png"}
                alt=""
                className="char-image-potrait shadow"
              />
              <img
                src={"/images/client/index/chat-screen-desktop.png"}
                alt=""
                className="char-image-desktop shadow"
              />
            </div>
          </div>
        </section>

        <section className="product-discovery">
          <div className="container">
            <h1 className="title">{t("marketing.discovery.title")}</h1>
            <p>
              {t("marketing.discovery.description")}
            </p>
            <NotifyButton />
            <div className="product-discovery-images">
              <img
                src={"/images/client/index/mobo landscape.png"}
                alt=""
                className="landscape shadow"
              />
              <img
                src={"/images/client/index/mobile-search.png"}
                alt=""
                className="potrait shadow"
              />
              <img
                src={"/images/client/index/search.png"}
                alt=""
                className="search shadow"
              />
            </div>
          </div>
        </section>

        <section className="fee-structure">
          <div className="container">
            <img
              src={"/images/client/index/fee structure mobo 2.png"}
              alt=""
              className="fee-image-potrait shadow"
            />
            <div className="col-right">
              <h1 className="title">{t("marketing.fee_structure.title")}</h1>
              <p>
                {t("marketing.fee_structure.description")}
              </p>
              <NotifyButton />
              <img
                src={"/images/client/index/fee structure 2.png"}
                alt=""
                className="fee-image-lanscape shadow"
              />
            </div>
          </div>
        </section>

        <section className="subscription">
          <div className="container">
            <h1 className="title">{t("marketing.subscription.title")}</h1>
            <p>{t("marketing.subscription.description")}</p>
            <NotifyButton />
            <img
              src={"/images/client/index/vendor presence 2.png"}
              alt=""
              className="sub-image shadow"
            />
          </div>
        </section>

        <section className="empower-vendors">
          <div className="container">
            <div className="col-left">
              <h1 className="title">{t("marketing.empower.title")}</h1>
              <p>
                {t("marketing.empower.description")}
              </p>
              <NotifyButton />
            </div>
            <div className="col-right">
              <img
                src={"/images/client/index/Empowering Vendors 2.png"}
                alt=""
                className="image-vendor shadow"
              />
            </div>
          </div>
        </section>

        <section className="buyer-experience">
          <div className="container">
            <div className="col-left">
              <img
                src={"/images/client/index/intro.png"}
                alt=""
                className="image-buyer shadow"
              />
            </div>
            <div className="col-right">
              <h1 className="title">{t("marketing.experience.title")}</h1>
              <p>
                {t("marketing.experience.description")}
              </p>
              <NotifyButton />
            </div>
          </div>
        </section>

        <section className="mobile-app">
          <div className="container">
            <div className="col-left">
              <h1 className="title">{t("marketing.mobile_app.title")}</h1>
              <p>{t("marketing.mobile_app.description")}</p>
              <NotifyButton />
            </div>
            <div className="col-right">
              <img
                src={"/images/client/index/mobile-app-help.png"}
                alt=""
                className="help shadow"
              />
              <img
                src={"/images/client/index/mobile-app-intro.png"}
                alt=""
                className="intro shadow"
              />
              <img
                src={"/images/client/index/mobile-app-chat.png"}
                alt=""
                className="chat shadow"
              />
            </div>
          </div>
        </section>

        <section className="faqs">
          <div className="container">
            <div className="faq-title">
              <h1 className="title">{t("marketing.faqs.title")}</h1>
              <p>
                {t("marketing.faqs.description")}
              </p>
            </div>
            <div className="questions-answers">
              <details>
                <summary className="summary">{t("marketing.faqs.q1.q")}</summary>
                <p>
                  {t("marketing.faqs.q1.a")}
                </p>
              </details>

              <details>
                <summary className="summary">
                  {t("marketing.faqs.q2.q")}
                </summary>
                <p>
                  {t("marketing.faqs.q2.a")}
                </p>
              </details>

              <details>
                <summary className="summary">{t("marketing.faqs.q3.q")}</summary>
                <p>
                  {t("marketing.faqs.q3.a")}
                </p>
              </details>
              <details>
                <summary className="summary">
                  {t("marketing.faqs.q4.q")}
                </summary>
                <p>
                  {t("marketing.faqs.q4.a")}
                  {/* <!-- if platform is launched, provide a link to sign up --> */}
                </p>
              </details>

              <details>
                <summary className="summary">
                  {t("marketing.faqs.q5.q")}
                </summary>
                <p>
                  {t("marketing.faqs.q5.a")}
                </p>
              </details>

              <details>
                <summary className="summary">
                  {t("marketing.faqs.q6.q")}
                </summary>
                <p>
                  {t("marketing.faqs.q6.a")}
                </p>
              </details>
              <details>
                <summary className="summary">
                  {t("marketing.faqs.q7.q")}
                </summary>
                <p>
                  {t("marketing.faqs.q7.a")}
                  {/* <!-- if platform is launched, provide a full user-flow for accessing chat --> */}
                </p>
              </details>

            </div>
          </div>
        </section>
      </main>

      <Footer />
    </>
  );
};

export default Marketing;
