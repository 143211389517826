import React from "react";

const BusinessPage = () => {
    return (
        <div className="business-page">
            <h1>Business page</h1>
        </div>
    )
}

export default BusinessPage