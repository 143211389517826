import React from 'react';
import { useTranslation } from 'react-i18next';


const LanguageSwitcher = () => {
    const { i18n } = useTranslation();
    const lang = i18n.translator.language
    const changeLanguage = (language) => {
        i18n.changeLanguage(language);
    };
    return (
        <div className='language-switcher'>
            <div className={lang === "en" ? "active language" : 'language'} onClick={() => changeLanguage('en')}> <img src={"/images/lang/en.png"} alt="" className="flag" /> <span>EN</span></div>
            <div className={lang === "fr" ? "active language" : 'language'} onClick={() => changeLanguage('fr')}> <span>FR</span> <img src={"/images/lang/fr.png"} alt="" className="flag" /> </div>
            {/* <div className={lang === "kn" ? "active" : ''} onClick={() => changeLanguage('kn')}>Kinya</div> */}
        </div>
    );
};

export default LanguageSwitcher;
