import { useEffect, useState } from "react";
import { jwtDecode } from "jwt-decode";

export const useAuthentication = () => {
    const [isAuth, setIsAuth] = useState(null);
    useEffect(() => {
        const token = localStorage.getItem("access");
        if (token !== null) {
            const tokenExpired = isTokenExpired(token);

            if (tokenExpired) {
                setIsAuth(false)
            } else {
                setIsAuth(true);
            }
        }
    }, []);

    const logout = () => {
        localStorage.clear();
        window.location.href = "/";
    };
    return { isAuth, logout };
};

function isTokenExpired(token) {
    if (!token) return true;

    const decodeToken = jwtDecode(token);
    const expirationTime = decodeToken.exp * 1000
    const currentTime = Date.now();

    return expirationTime < currentTime
}