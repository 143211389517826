import React from "react";

const LoadingIcon = ({ text }) => {
    return (
        <div className="loading">
            <i className="fa-solid fa-spinner loading-icon"></i>
            <p>{text}</p>
        </div>
    )
}

export default LoadingIcon