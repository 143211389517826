import React, { useState } from "react";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";

const NotifyButton = ({ togglePopup }) => {
    const { t } = useTranslation();

    return (
        <button onClick={togglePopup} className="btn btn-primary open-pop-up">
            {t('buttons.notify_me')}
            <span>
                <i className="fa fa-bell-o icon-link"></i>
            </span>
        </button>
    )
}
const BaseHeader = ({ togglePopup }) => {
    const [isMenuOpen, setIsMenuOpen] = useState(false);
    const handleOpenMenu = () => {
        setIsMenuOpen(true);
    };

    const handleCloseMenu = () => {
        setIsMenuOpen(false);
    };

    return (
        <header>
            <nav className="container">
                <div className="branding">
                    <img
                        src={"/images/branding/huzahills-icon-green.png"}
                        alt=""
                        className="site-icon"
                    />
                    <h1 className="site-name">Huzahills</h1>
                </div>
                <div className={isMenuOpen ? "menu-open" : "menu"}>

                    <div className="social-icons">
                        <Link to="https://instagram.com/huzahills?igshid=YTQwZjQ0NmI0OA==">
                            <i className="social-icon fab fa-instagram"></i>
                        </Link>
                        <Link to="https://x.com/huzahills?s=21">
                            <i className="fa-brands fa-x-twitter"></i>
                        </Link>
                        <Link to="https://www.tiktok.com/@huza.hills?_t=8gzQTaX3a7C&_r=1">
                            <i className="fa-brands fa-tiktok"></i>
                        </Link>
                        <Link to="https://www.linkedin.com/in/huza-hills-8a1656299/">
                            <i className="fa-brands fa-linkedin-in"></i>
                        </Link>
                    </div>

                    <NotifyButton togglePopup={togglePopup} />

                </div>
                {!isMenuOpen && (
                    <img
                        src={"/images/client/icons/menu-icon-open.svg"}
                        alt=""
                        className={"open-menu"}
                        onClick={handleOpenMenu}
                    />
                )}

                {isMenuOpen && (
                    <img
                        src={"/images/client/icons/menu-icon-close.svg"}
                        alt=""
                        className={"close-menu"}
                        onClick={handleCloseMenu}
                    />
                )}
            </nav>
        </header>
    )
}

export default BaseHeader