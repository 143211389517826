import React, { useState } from "react";
import axios from 'axios'
import { Helmet } from "react-helmet";
import BaseHeader from "../../components/general/baseHeader";
import Footer from "../../components/general/footer";
import { API_URL } from "../../api";
import toast from "react-hot-toast";

const ForgotPasswordPage = () => {
    const [isLoading, setIsLoading] = useState(false)
    const [resettingPassword, setIsResettingPassword] = useState(false)
    const [codeIsSent, setCodeIsSent] = useState(false)

    const [resetCode, setResetCode] = useState("")
    const [newPassword, setNewPassword] = useState("")

    // form
    const [userEmail, setUserEmail] = useState("")

    const handleSendCode = async () => {
        setIsLoading(true)

        try {
            const response = await axios.post(`${API_URL}/accounts/forgot-password/`, { "email": userEmail })
            if (response.status === 200) {
                toast.success("A code has been sent to your email")
                setIsLoading(false)
                setCodeIsSent(true)
            }
        } catch (error) {
            if (error.response.data) {
                toast.error(error.response.data.error)
                setIsLoading(false)
            } else {
                console.log(error)
                toast.error("Network error")
                setIsLoading(false)
            }
        }

    }

    const handleResting = async () => {
        setIsResettingPassword(true)
        const userData = {
            "email": userEmail,
            "reset_code": resetCode,
            "new_password": newPassword
        }
        try {
            const response = await axios.post(`${API_URL}/accounts/reset-password/`, userData)
            if (response.status === 200) {
                toast.success("Your password has been changed... you can now login")
                window.location.href = "/accounts/login/"
                setIsResettingPassword(false)

            }
        } catch (error) {
            if (error.response.data) {
                toast.error(error.response.data.error)
                setIsResettingPassword(false)
            } else {
                console.log(error)
                toast.error("Network error")
                setIsResettingPassword(false)
            }
        }
    }
    return (
        <div className="forgot-password-page">
            <Helmet>
                <title>Forgot password | Huzahills</title>
            </Helmet>
            <BaseHeader />
            <div className="container ">
                {
                    codeIsSent
                        ? <div className="forgot-password-form">
                            <h1 className="title">Enter verification code</h1>
                            <p>A 6 digits code has been sent to you email for verification. Enter it below, along with your email, new password, then click Reset Password</p>
                            <form className="form" id="passwordResetForm">
                                <input
                                    onChange={(e) => setResetCode(e.target.value)}
                                    value={resetCode}
                                    type="text" name="resetCode" id="resetCode" placeholder="Reset code" />
                                <input
                                    onChange={(e) => setNewPassword(e.target.value)}
                                    value={newPassword}
                                    type="password" name="newPassword" id="newPassword" placeholder="New password" />
                                <button
                                    onClick={handleResting}
                                    type="button" className="primary-button">
                                    {resettingPassword
                                        ? <span>Resetting password</span>
                                        :
                                        <span>Reset password</span>
                                    }
                                </button>
                            </form>
                        </div>
                        : <div className="forgot-password-form">
                            <h1 className="title">Forgot Your Password?</h1>
                            <p>
                                No worries! Just enter your email address below and we'll send you a link to reset your password.
                            </p>

                            <form className="form" id="forgotPasswordForm">
                                <input
                                    onChange={(e) => setUserEmail(e.target.value)}
                                    value={userEmail}
                                    type="text" name="userEmail" id="userEmail" placeholder="Enter your email" />
                                <button
                                    onClick={handleSendCode}
                                    type="button" className="primary-button">
                                    {
                                        isLoading
                                            ? <span>Sending code...</span>
                                            : <span>Get code</span>
                                    }

                                </button>
                            </form>
                        </div>

                }

            </div>
            <Footer />

        </div>
    )

}

export default ForgotPasswordPage