import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import toast from "react-hot-toast";
import axios from 'axios';
import { API_URL } from "../../api";
import LoadingIcon from "../general/loadingIcon";

const NotifyMeForm = ({ togglePopup }) => {
    const { t } = useTranslation()
    const [isLoading, setIsLoading] = useState(false)
    const [submitted, setSubmitted] = useState(false)

    // form

    const [userEmail, setUserEmail] = useState("")
    const [fullName, setFullName] = useState("")
    const handleSubmit = async () => {
        if (fullName === '' && userEmail === '') {
            toast.error("Email and name are required")
        } else if (fullName === '') {
            toast.error("Name is required")
        } else if (userEmail === '') {
            toast.error("Email is required")
        } else {
            setIsLoading(true)

            const subscriberData = {
                "full_name": fullName,
                "user_email": userEmail
            }

            try {
                const response = await axios.post(`${API_URL}/followers/subscribe/`, subscriberData)
                if (response.status === 201) {
                    setSubmitted(true)
                    setIsLoading(false)
                }
            } catch (error) {
                if (error.response.data) {
                    setIsLoading(false)
                    toast.error(error.response.data.error)
                } else {
                    toast.error("Network Error")
                }
            }
        }

    }
    return (
        <div>
            {
                submitted
                    ? <div className="content">
                        <h2>{t("notify_me_form.success.title")}</h2>
                        <p>{t("notify_me_form.success.description")}</p>
                        <button onClick={togglePopup} type="button" className="primary-button">Ok</button>
                    </div>
                    : <div className="content">
                        <h2>{t("notify_me_form.title")}</h2>
                        <p>
                            {t("notify_me_form.description")}
                        </p>

                        <form className="form">
                            <input
                                onChange={(e) => setFullName(e.target.value)}
                                value={fullName}
                                type="text" name="userFullName" id="userFullName" placeholder={t("notify_me_form.form.full_name")} />
                            <input
                                onChange={(e) => setUserEmail(e.target.value)}
                                value={userEmail}
                                type="email" name="userEmail" id="userEmail" placeholder={t("notify_me_form.form.email")} />
                            <button
                                onClick={handleSubmit}
                                type="button" className="primary-button">
                                {
                                    isLoading
                                        ? <LoadingIcon />
                                        : <span>{t("notify_me_form.form.button_text")}</span>
                                }
                            </button>
                        </form>

                        <small>{t("notify_me_form.agreement")}</small>

                    </div>
            }

        </div>
    )
}

export default NotifyMeForm