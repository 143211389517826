import React, { useEffect, useState } from "react";
import axios from 'axios';
import toast from "react-hot-toast";
import { Helmet } from "react-helmet";
import { useGoogleLogin } from '@react-oauth/google';
import { API_URL } from "../../api";
import { useTranslation } from 'react-i18next';
import LoadingIcon from "../general/loadingIcon";


const SingUpForm = ({ switchForm }) => {
    const { t } = useTranslation()
    const [accessToken, setAccessToken] = useState("")
    const [showPassword, setShowPassword] = useState(false)
    const [isSigningUp, setIsSigningUp] = useState(false)
    const [isSigningUpWithGoogle, setIsSigningUpWithGoogle] = useState(false)

    // form
    const [fullName, setFullName] = useState("")
    const [userEmail, setUserEmail] = useState("")
    const [userPassword, setUserPassword] = useState("")


    const handleEmailSignUp = async () => {
        setIsSigningUp(true)

        if (fullName === '' && userEmail === '' && userPassword === '') {
            toast.error("Name, email and password are required")
            setIsSigningUp(false)
        }
        else if (fullName === '') {
            toast.error("Name is required")
            setIsSigningUp(false)
        }
        else if (userEmail === '') {
            toast.error("Email is required")
            setIsSigningUp(false)
        } else if (userPassword === '') {
            toast.error("Password is required")
            setIsSigningUp(false)
        } else {
            const userData = {
                "user_email": userEmail,
                "full_name": fullName,
                "user_password": userPassword,
            }

            try {
                const response = await axios.post(`${API_URL}/accounts/sign_up/`, userData)
                console.log(response)
                if (response.status === 200) {
                    setIsSigningUp(false)
                    toast.success("Your account is created")
                    window.location.href = "/"

                }
            } catch (error) {
                if (error.response.data) {
                    toast.error(error.response.data.error)
                    setIsSigningUp(false)
                } else {
                    toast.error("Network Error")
                    setIsSigningUp(false)
                }
            }
        }
    }
    const toggleShowPassword = () => {
        setShowPassword(!showPassword)
    }

    const login = useGoogleLogin({

        onSuccess: tokenResponse => {
            setAccessToken(tokenResponse.access_token);
            setIsSigningUp(true)
        },
    });

    useEffect(() => {

        if (accessToken) {

            axios.get(`https://www.googleapis.com/oauth2/v1/userinfo?access_token=${accessToken}`, {
                headers: {
                    Authorization: `Bearer ${accessToken}`,
                    Accept: 'application/json'
                }
            }).then((res) => {
                setIsSigningUp(true)
                axios.post(`${API_URL}/accounts/sign_up/social/google/`, { "access_token": accessToken })
                    .then((response) => {
                        console.log(response);
                        if (response.status === 201) {
                            localStorage.setItem("access", response.data.access);
                            window.location.href = "/"
                            setIsSigningUp(false)
                        }
                    })
                    .catch((error) => {
                        if (error.response.data) {
                            toast.error(error.response.data.error)
                            setIsSigningUp(false)
                        } else {
                            toast.error("Network Error")
                            setIsSigningUp(false)
                        }
                    });
            }).catch((err) => console.log(err));
        };
    },
        [accessToken]
    );

    return (
        <div>
            <Helmet>
                <title>Sign up | Huzahills</title>
            </Helmet>

            <div className="account-form">
                <h2 className="title">{t("sign_up.title")}</h2>
                <p>{t("sign_up.decryption")}
                </p>
                <form className="form" id="signUpForm">
                    <input
                        onChange={(e) => setFullName(e.target.value)}
                        value={fullName}
                        type="text" name="userFullName" id="userFullName" placeholder={t("sign_up.form.fullName")} />
                    <input
                        onChange={(e) => setUserEmail(e.target.value)}
                        value={userEmail}
                        type="email" name="userEmail" id="userEmail" placeholder={t("sign_up.form.email")} />

                    <div className="password-input">
                        <input
                            onChange={(e) => setUserPassword(e.target.value)}
                            value={userPassword}
                            type={showPassword ? "text" : "password"} name="userPassword" id="userPassword" placeholder={t("sign_up.form.password")} />
                        <div onClick={toggleShowPassword} className="icon">
                            {
                                showPassword ?
                                    <i className="fa-regular fa-eye"></i>
                                    : <i className="fa-regular fa-eye-slash"></i>
                            }
                        </div>
                    </div>

                    <div className="buttons">
                        <button type="button" onClick={handleEmailSignUp} className="primary-button">

                            {isSigningUp
                                ? <LoadingIcon />
                                : <span>{t("buttons.sign_up")}</span>}
                        </button>
                    </div>
                </form>
                <span>{t("sign_up.or")}</span>
                <button className="outline-button" onClick={() => login()}>
                    {
                        isSigningUpWithGoogle
                            ? <LoadingIcon />
                            : <span>{t("sign_up.google")}</span>
                    }

                </button>
                <p>{t("sign_up.have_account")}?  <span className="form-link" onClick={switchForm}>{t("buttons.login")}</span></p>
            </div>

        </div>
    )
}

export default SingUpForm