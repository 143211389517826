import React from "react";

const PopupContainer = ({ content, closePopup }) => {
    return (
        <div className="popup">
            <div className="popup-content">
                <div onClick={closePopup} className="close-popup">
                    <i className="fa-solid fa-xmark"></i>
                </div>
                {content}
            </div>
        </div>
    )
}

export default PopupContainer