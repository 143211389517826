import { BrowserRouter, Routes, Route } from "react-router-dom";
import { Toaster } from "react-hot-toast";
import { Helmet } from "react-helmet";
import Marketing from "./pages/marketing";
import ProtectedRoute from "./components/contexts/protectedRoute";
import OverviewPage from "./pages/dashboard/overviewPage";
import BusinessPage from "./pages/landing/businessPage";
import { GoogleClientId } from "./api";

// Auth
import { GoogleOAuthProvider } from '@react-oauth/google';
import LoginPage from "./components/forms/loginForm";
import SingUpPage from "./components/forms/signUpForm";
import AccountsPage from "./pages/auth/accountsPage";

// Translation
import { initReactI18next } from 'react-i18next';
import { I18nextProvider } from 'react-i18next';
import i18n from 'i18next';
import LanguageSwitcher from "./components/general/languageSwithwe";
import enTranslation from './translations/en.json'
import frTranslation from './translations/fr.json'
import kinyaTranslation from './translations/kinya.json'
// end of translation


import './assets/css/main/main.css'
import ForgotPasswordPage from "./pages/auth/forgotPassword";
import NewAccountPage from "./pages/auth/newAccountPage";
import PricingPage from "./pages/pricing/pricingPage";

const resources = {
  en: enTranslation,
  fr: frTranslation,
  kn: kinyaTranslation
};

i18n
  .use(initReactI18next)
  .init({
    resources,
    lng: 'en',
    interpolation: {
      escapeValue: false,
    },
  });

function App() {
  return (
    <I18nextProvider i18n={i18n}>
      <GoogleOAuthProvider
        clientId={GoogleClientId}
      >
        <BrowserRouter>
          <div>
            <LanguageSwitcher />
            <Routes>
              <Route path="/" element={<Marketing />} />
              <Route path="/business/" element={<ProtectedRoute><BusinessPage /></ProtectedRoute>} />
              <Route path="/dashboard/" element={<ProtectedRoute><OverviewPage /></ProtectedRoute>} />
              <Route path="/marketing/" element={<ProtectedRoute><Marketing /></ProtectedRoute>} />
              <Route path="/checkout/" element={<ProtectedRoute><PricingPage /></ProtectedRoute>} />

              {/* Authentication */}
              <Route path="/accounts/new/" element={<ProtectedRoute><NewAccountPage /></ProtectedRoute>} />
              <Route path="/accounts/" element={<AccountsPage />} />
              <Route path="/accounts/forgot-password/" element={<ForgotPasswordPage />} />
              <Route path="/accounts/signup/" element={<SingUpPage />} />

            </Routes>

            <Helmet>
              <link rel="stylesheet" href="https://cdnjs.cloudflare.com/ajax/libs/font-awesome/6.4.0/css/all.min.css"></link>
            </Helmet>
            <Toaster
              position="bottom-right"
              reverseOrder={false}
            />
          </div>
        </BrowserRouter>
      </GoogleOAuthProvider>
    </I18nextProvider>

  );
}

export default App;
