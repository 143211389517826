import React, { useEffect, useState } from "react";
import { Helmet } from "react-helmet";
import LoginForm from "../../components/forms/loginForm";
import SignUpForm from "../../components/forms/signUpForm";
import { promoData } from "../../constants";
import { useTranslation } from 'react-i18next';

import '../../assets/css/pages/accounts/accounts.css'
import BaseHeader from "../../components/general/baseHeader";
import Footer from "../../components/general/footer";

const AccountsPage = () => {
    const { t } = useTranslation()
    const [isNewUser, setIsNewUser] = useState(false)
    const [promoPercentage, setPromoPercentage] = useState(50)
    const [promoDescription, setPromoDescription] = useState("")

    const switchForm = () => {
        setIsNewUser(!isNewUser)
    }
    useEffect(() => {
        const getPromoData = () => {
            setPromoDescription(promoData.description)
            setPromoPercentage(promoData.percentage)
        }
        getPromoData()
    })

    return (
        <div>
            <Helmet>
                <title>{isNewUser ? "Login" : "Sing up"} | Huzahills</title>
            </Helmet>
            <BaseHeader />
            <div className="accounts-page">
                <div className="container">
                    {
                        isNewUser
                            ? <SignUpForm switchForm={switchForm} />
                            : <LoginForm switchForm={switchForm} />
                    }

                    <div className="promo">
                        <div className="promo-img">
                            <img src={"/images/accounts/login promo.png"} alt="" />
                            <div className="promo-content">
                                <h1>Get {promoPercentage}%</h1>
                                <p>{promoDescription}</p>
                            </div>

                            <div className="overlay"></div>
                        </div>
                    </div>
                </div>
            </div>
            <Footer />
        </div>
    )
}

export default AccountsPage